(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("clappr"), require("shaka"));
	else if(typeof define === 'function' && define.amd)
		define(["clappr", "shaka"], factory);
	else if(typeof exports === 'object')
		exports["DashShakaPlayback"] = factory(require("clappr"), require("shaka"));
	else
		root["DashShakaPlayback"] = factory(root["Clappr"], root["shaka"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__) {
return 